import React from "react";
import { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Container,
    Box,
    MenuItem,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    CssBaseline,
} from "@mui/material";
import useOrderManager from "../hooks/useOrderManager";
import ImageCard from "../layout/ImageCard"; // 引入 ImageCard 组件
import "../../assets/styles/OrderManager.css";
import axios from "axios";
import { API_URL, WS_URL } from '../../config';

const OrderManager = () => {
    const {
        brand,
        subcategory,
        model,
        modelNumber,
        startYear,
        setStartYear,
        endYear,
        setEndYear,
        brands,
        subcategories,
        models,
        modelNumbers,
        rows,
        orderStatus,
        setOrderStatus,
        orderStatuses,
        handleBrandChange,
        handleSubcategoriesChange,
        handleModelChange,
        handleModelNumberChange,
        handleSearchOrder,
        handleSearchCar,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        databaseCar,
        handleReject,
        handleApprove,
        handleFinishOrder,
        orderId,
        setOrderId,
        handleStartProduct,
        handleDeliver,
    } = useOrderManager();

    const [selectedRow, setSelectedRow] = useState("");

    const [orderAutomobileBrandName, setOrderAutomobileBrandName] = useState("");
    const [orderBrandTypeName, setOrderBrandTypeName] = useState("");
    const [orderSubBrandTypeName, setOrderSubBrandTypeName] = useState("");
    const [orderSerialNum, setOrderSerialNum] = useState("");

    const [localOrderCarImages, setLocalOrderCarImages] = useState([]);

    const [orderProductStartTime, setOrderProductStartTime] = useState("");
    const [orderProductEndTime, setOrderProductEndTime] = useState("");

    const [localImagePaths, setLocalImagePaths] = useState([]);

    // 声明状态变量
    const [trackingNumber, setTrackingNumber] = useState(""); // 对应快递单号
    const [courierName, setCourierName] = useState("");       // 对应快递名称
    const [courierCode, setCourierCode] = useState("");       // 对应快递编码

    // 使用状态来跟踪选中的行ID
    const [selectedRowId, setSelectedRowId] = useState(null);

    /**
     * 处理行点击事件
     * @param {Object} row - 被点击的行数据
     */
    const handleRowClick = (row) => {
        setSelectedRowId(row.id); // 设置选中的行ID
        setSelectedRow(row); // 可能的选中行状态
        setOrderId(row.id); // 设置订单ID
        handleShowOrderDetail(row); // 显示订单详情
    };

    const resetOrderDetails = () => {
        // 重置 handleShowOrderDetail 中的状态
        setSelectedRow("");
        setSelectedRowId(null);
        setOrderId(-1);

        setOrderAutomobileBrandName("");
        setOrderBrandTypeName("");
        setOrderSubBrandTypeName("");
        setOrderSerialNum("");

        setOrderProductStartTime("");
        setOrderProductEndTime("");

        setLocalOrderCarImages([]);
        setLocalImagePaths([]);
    };

    // 在 handleOrderSearchClick 中调用重置函数
    const handleOrderSearchClick = () => {
        resetOrderDetails();
        handleSearchOrder();
    };

    const handleShowImage = async (orderCarImages) => {
        try {
            const imageUrls = [
                ...orderCarImages.carImages.map(image => image.url),
                ...orderCarImages.drivingLicenseImages.map(image => image.url),
            ];

            // 判断 imageUrls 是否为空
            if (imageUrls.length === 0) {
                console.warn('No images available to download.');
                setLocalOrderCarImages([]); // 设置为空，避免无图片情况下出错
                return;
            }

            // 调用后端API下载图片
            const response = await axios.post('/api/get-images', { imageUrls });

            if (response.data.success) {
                const base64Images = response.data.images; // 获取Base64编码的图片数组
                const imageElements = base64Images.map(base64 => `data:image/jpeg;base64,${base64}`);
                setLocalOrderCarImages(imageElements); // 将Base64编码图片显示在前端
            } else {
                console.error('Failed to fetch images:', response.data.message);
            }
        } catch (err) {
            console.error('Failed to fetch images from backend:', err);
            setLocalOrderCarImages([]);
        }
    };

    useEffect(() => {
        // 如果databaseCar不为空，则调用handleShowDatabaseImage
        if (databaseCar && databaseCar !== "") {
            handleShowDatabaseImage();
        }
    }, [databaseCar]); // 监听databaseCar的变化


    const handleShowDatabaseImage = async () => {
        try {
            if (!databaseCar.image_url) {
                console.warn('No image URL available to download.');
                setLocalImagePaths([]); // 设置为空，避免无图片情况下出错
                return;
            }
            // 调用后端API获取签名URL
            const response = await axios.get(`${API_URL}/api/get-image`, {
                params: { imageUrl: databaseCar.image_url },
            });

            if (response.data.success) {
                const base64Image = response.data.image;
                const imageElement = `${base64Image}`;
                setLocalImagePaths([imageElement]);
            } else {
                console.error('Failed to fetch signed URL:', response.data.message);
            }
        } catch (err) {
            console.error('Failed to fetch image from OSS:', err);
            setLocalImagePaths([]);
        }
    };

    const handleShowOrderDetail = (row) => {
        // Add your logic here, e.g., navigate to a detail page or open a modal
        console.log("Show order detail:", row);
        setOrderAutomobileBrandName(row.automobile_brand_name);
        setOrderBrandTypeName(row.brand_type_name);
        setOrderSubBrandTypeName(row.sub_brand_type_name);
        setOrderSerialNum(row.serial_num);
        setOrderProductStartTime(row.product_start_time);
        setOrderProductEndTime(row.product_end_time);

        handleShowImage(row);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CssBaseline />
            <Container className="orderManager-container" sx={{ flexGrow: 1, marginBottom: 4, border: '1px solid #ddd', borderRadius: '8px', width: '100%', maxWidth: 'none', }}>
                <Box
                    sx={{
                        width: '100%',
                        p: 2,
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        marginBottom: 2,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                label="订单状态"
                                select
                                value={orderStatus}
                                onChange={(e) => setOrderStatus(e.target.value)}
                            >
                                {orderStatuses.map((oStatus) => (
                                    <MenuItem key={oStatus.id} value={oStatus.id}>
                                        {oStatus.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOrderSearchClick}
                                sx={{ marginTop: 1, fontSize: 'large', width: '100px' }}
                            >
                                查询
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                    <Table aria-label="simple table" sx={{ minWidth: 1800 }} >
                        <TableHead>
                            <TableRow>
                                <TableCell>订单id</TableCell>
                                <TableCell>姓名</TableCell>
                                <TableCell>电话</TableCell>
                                <TableCell>订单日期</TableCell>
                                <TableCell>安装地点</TableCell>
                                <TableCell>安装行政区</TableCell>
                                <TableCell>安装详细地点</TableCell>

                                <TableCell>皮革类型</TableCell>
                                <TableCell>皮革材质</TableCell>

                                <TableCell>安装价格</TableCell>
                                <TableCell>皮革价格</TableCell>
                                <TableCell>总价格</TableCell>

                                <TableCell>快递单号</TableCell>
                                <TableCell>快递名称</TableCell>
                                <TableCell>快递编码</TableCell>

                                <TableCell>订单描述</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    Math.min(page * rowsPerPage + rowsPerPage, rows.length)
                                )
                                .map((row) => (
                                    <TableRow
                                        key={row.id}
                                        onClick={() => handleRowClick(row)}
                                        style={{
                                            backgroundColor: selectedRowId === row.id ? '#f0f0f0' : 'inherit', // 选中行高亮
                                            cursor: 'pointer' // 鼠标悬停效果
                                        }}
                                    >
                                        <TableCell>{row.wechat_order_id}</TableCell>
                                        <TableCell>{row.guess_name}</TableCell>
                                        <TableCell>{row.telephone}</TableCell>
                                        <TableCell>{row.order_time}</TableCell>

                                        <TableCell>{row.install_location}</TableCell>
                                        <TableCell>{row.install_administrative_location}</TableCell>
                                        <TableCell>{row.install_detail_location}</TableCell>
                                        <TableCell>{row.leather_type}</TableCell>
                                        <TableCell>{row.leather_material}</TableCell>
                                        <TableCell>{row.install_price}</TableCell>
                                        <TableCell>{row.leather_price}</TableCell>
                                        <TableCell>{row.total_price}</TableCell>
                                        <TableCell>{row.tracking_number}</TableCell>
                                        <TableCell>{row.courier_name}</TableCell>
                                        <TableCell>{row.courier_code}</TableCell>
                                        <TableCell>{row.car_detail_info}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                    </Table>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

                <Grid container spacing={6} sx={{
                    // width: "calc(100% - 48px)", // Adjust for padding
                    // p: 2,
                    // border: "1px solid #ddd",
                    // borderRadius: "8px",
                    // overflow: "hidden",
                    marginTop: 1,
                    // marginLeft: "32px", // Align content
                }}>
                    <Grid item xs={9}>
                        <ImageCard title="订单相关图片" images={localOrderCarImages} />
                    </Grid>
                    <Grid item xs={3}>
                        <Box className="control-panel" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: 2 }}>
                            <TextField label="品牌" fullWidth margin="normal" value={orderAutomobileBrandName} />
                            <TextField label="车系" fullWidth margin="normal" value={orderBrandTypeName} />
                            <TextField label="车型" fullWidth margin="normal" value={orderSubBrandTypeName} />
                            <TextField label="车型编号" fullWidth margin="normal" value={orderSerialNum} />
                            <TextField label="起始年份" fullWidth margin="normal" value={orderProductStartTime} />
                            <TextField label="结束年份" fullWidth margin="normal" value={orderProductEndTime} />
                        </Box>
                    </Grid>

                    <Box
                        sx={{
                            width: 'calc(100% - 48px)', // 减去左右总共的padding（24px*2）以适应Grid间隔
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            marginTop: 4,
                            marginLeft: '48px', // 增加左边距以对齐内容
                        }}
                    >
                        <Grid container spacing={2}> {/* 使用Grid容器，设置元素间距为2 */}
                            <Grid item xs={12} sm={6} md={1.5}> {/* 调整每个TextField的宽度 */}
                                <TextField
                                    fullWidth
                                    label="品牌"
                                    select
                                    value={brand}
                                    onChange={handleBrandChange}
                                >
                                    {brands.map((brand) => (
                                        <MenuItem key={brand.id} value={brand.id}>
                                            {brand.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="车系"
                                    select
                                    value={subcategory}
                                    onChange={handleSubcategoriesChange}
                                    disabled={!brand}
                                >
                                    {subcategories.map((subcategory) => (
                                        <MenuItem
                                            key={subcategory.id}
                                            value={subcategory.id}
                                        >
                                            {subcategory.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="车型"
                                    select
                                    value={model}
                                    onChange={handleModelChange}
                                    disabled={!subcategory}
                                >
                                    {models.map((model) => (
                                        <MenuItem
                                            key={model.id}
                                            value={model.id}
                                        >
                                            {model.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="车型编号"
                                    select
                                    value={modelNumber}
                                    onChange={handleModelNumberChange}
                                    disabled={!model}
                                >
                                    {modelNumbers.map((modelNumber) => (
                                        <MenuItem
                                            key={modelNumber.id}
                                            value={modelNumber.id}
                                        >
                                            {modelNumber.number}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="起始年份"
                                    value={startYear}
                                    onChange={(e) =>
                                        setStartYear(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="结束年份"
                                    value={endYear}
                                    onChange={(e) => setEndYear(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}> {/* 按钮居中显示 */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        handleSearchCar();
                                        // handleShowDatabaseImage();
                                    }}
                                    sx={{ marginTop: 1, fontSize: 'large', width: '100px' }}
                                >
                                    查询
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid item xs={9}>
                        <ImageCard title="数据库数据" images={localImagePaths} />
                    </Grid>
                    <Grid item xs={3}>
                        <Box className="control-panel"
                            // sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: 2 }}
                            sx={{
                                border: '1px solid #ddd', borderRadius: '8px', padding: 2, height: 'auto', overflow: 'hidden'
                            }}
                        >
                            <TextField
                                label="品牌"
                                fullWidth
                                margin="normal"
                                value={brands.find((b) => b.id === brand)?.name || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label="车系"
                                fullWidth
                                margin="normal"
                                value={subcategories.find((s) => s.id === subcategory)?.name || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label="车型"
                                fullWidth
                                margin="normal"
                                value={models.find((m) => m.id === model)?.name || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label="车型编号"
                                fullWidth
                                margin="normal"
                                value={modelNumbers.find((mn) => mn.id === modelNumber)?.number || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label="起始年份"
                                fullWidth
                                margin="normal"
                                value={databaseCar.product_start_time}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label="结束年份"
                                fullWidth
                                margin="normal"
                                value={databaseCar.product_end_time}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                            {/* 
                            <TextField 
                                label="领袖编号" 
                                fullWidth 
                                margin="normal" 
                                // value={leaderNumber} 
                                // InputProps={{ 
                                //     readOnly: true, 
                                // }} 
                            />
                            <TextField 
                                label="审核意见" 
                                fullWidth 
                                margin="normal" 
                                // value={reviewComments} 
                                // InputProps={{ 
                                //     readOnly: true, 
                                // }} 
                            /> 
                            */}
                            {/* <Button
                                variant="contained"
                                color="secondary"  // 设置为secondary，以便与“通过”按钮区分
                                fullWidth
                                style={{ marginTop: "1rem" }}
                                onClick={() => handleReject(orderId)}  // 使用箭头函数包装
                            >
                                不通过
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: "1rem" }}
                                onClick={() => handleApprove(orderId)}  // 使用箭头函数包装
                            >
                                通过
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: "1rem" }}
                                onClick={() => handleFinishOrder(orderId)}  // 使用箭头函数包装
                            >
                                生产完成
                            </Button> */}

                            {selectedRow.order_status_id === 4 && (
                                <>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        style={{ marginTop: "1rem" }}
                                        onClick={() => {
                                            resetOrderDetails(); // 重置状态
                                            handleReject(orderId);  // 调用拒绝处理
                                        }}
                                    >
                                        不通过
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        style={{ marginTop: "1rem" }}
                                        onClick={() => {
                                            resetOrderDetails(); // 重置状态
                                            handleApprove(orderId);  // 调用通过处理
                                        }}
                                    >
                                        通过
                                    </Button>
                                </>
                            )}
                            {selectedRow.order_status_id === 5 && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ marginTop: "1rem" }}
                                    onClick={() => {
                                        resetOrderDetails(); // 重置状态
                                        handleStartProduct(orderId);  // 调用开始生产处理
                                    }}
                                >
                                    开始生产
                                </Button>
                            )}
                            {selectedRow.order_status_id === 7 && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ marginTop: "1rem" }}
                                    onClick={() => {
                                        resetOrderDetails(); // 重置状态
                                        handleFinishOrder(orderId);  // 调用生产完成处理
                                    }}
                                >
                                    生产完成
                                </Button>
                            )}
                            {selectedRow.order_status_id === 8 && (
                                <>
                                    <TextField
                                        label="快递单号"
                                        fullWidth
                                        margin="normal"
                                        value={trackingNumber}
                                        onChange={(e) => setTrackingNumber(e.target.value)}
                                    />
                                    <TextField
                                        label="快递名称"
                                        fullWidth
                                        margin="normal"
                                        value={courierName}
                                        onChange={(e) => setCourierName(e.target.value)}
                                    />
                                    <TextField
                                        label="快递编码"
                                        fullWidth
                                        margin="normal"
                                        value={courierCode}
                                        onChange={(e) => setCourierCode(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        style={{ marginTop: "1rem" }}
                                        onClick={() => {
                                            resetOrderDetails(); // 重置状态
                                            handleDeliver(orderId, trackingNumber, courierName, courierCode);  // 调用发货处理
                                        }}
                                    >
                                        已发货
                                    </Button>
                                </>
                            )}

                        </Box>
                    </Grid>
                </Grid >
            </Container >
        </Box >
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default OrderManager;
